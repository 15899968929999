import styled from "styled-components";
import { useEffect, useState } from "react";

const CoinzillaWrapper = styled.div`
  margin-top: 1.5em;
`;

const CointrafficSidebar = () => {
  const [ssr, setSsr] = useState(true);
  useEffect(() => {
    setSsr(false);
  }, []);
  return (
    <CoinzillaWrapper>
      {!ssr && (
        <>
          <span id="ct_cSJOb8ijKl2" />
          <span id="ct_cG7c833NQw8" />
          <span id="ct_cis7X6rlJWC" />
        </>
      )}
    </CoinzillaWrapper>
  );
};

export default CointrafficSidebar;
